@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .table-y-spacing-2 {
    border-spacing: 0 theme("spacing.2");
  }
}

html {
  background-color: #f4f5f6;
}

/*
  Ideally we'd have a faded backdrop when a model dialog is open,
  similar to this CSS.

  I think it should be added automatically by tailwind elements, 
  but this isn't happening for some reason.
*/
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
